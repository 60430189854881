<template>
  <div class="myjourney-main">
    <div class="myjourney-content">

      <v-row>
        <v-col cols="auto">
          <v-icon size="42">commute</v-icon>
        </v-col>
        <v-col>
          <h1 class="display-1">{{ $t('main.MYJOURNEY_PAGES') }}</h1>
        </v-col>
        <v-col
          cols="auto"
          sm="4"
        >
          <!--
          <v-text-field
            v-model="search"
            solo
            dense
            prepend-inner-icon="search"
            :placeholder="$t('main.SEARCH')"
          />
          -->
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :disable-sort="true"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="footerProps"
        :loading="loading"
      >
        <template v-slot:item.link="{ item }">
          <v-btn
            v-if="item.isActive"
            :href="item.link"
            color="primary"
            small
            target="_blank"
          >
            {{ $t('main.OPEN_ACTION') }}
          </v-btn>

          <span v-if="!item.isActive">{{ $t('ui.Expired') }}</span>
        </template>
        <template v-slot:item.restrictionType="{ item }">
          <span v-if="item.restrictionType == 'TOURISM'">{{ $t('main.PRIVATE_TRAVEL') }}</span>
          <span v-if="item.restrictionType == 'BUSINESS'">{{ $t('main.BUSINESS_TRAVEL') }}</span>
        </template>
        <template v-slot:item.dateStart="{ item }">
          {{ item.dateStart|i18n_dateToGerman }}
        </template>
        <template v-slot:item.dateEnd="{ item }">
          {{ item.dateEnd|i18n_dateToGerman }}
        </template>
        <template v-slot:item.sendUpdates="{ item }">
          <v-icon v-if="item.sendUpdates">done</v-icon>
          <span v-if="!item.sendUpdates">-</span>
        </template>
        <template v-slot:item.showEvents="{ item }">
          <v-icon v-if="item.showEvents">done</v-icon>
          <span v-if="!item.showEvents">-</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span class="text-no-wrap">{{ item.createdAt|i18n_datetimeToGerman }}</span>
        </template>
      </v-data-table>

      <v-alert
        class="mt-4"
        dense
        type="info"
        text
      >
        {{ $t('main.MYTRIP_HISTORY_DISCLAIMER') }}
      </v-alert>
    </div>

  </div>
</template>

<script>
import { debounce } from "debounce"

export default {
  name: 'MyJourneyHistory',
  data() {
    return {
      loading: false,
      search: '',
      totalCount: null,
      options: {},
      footerProps: {
        itemsPerPageOptions: [5, 10, 20, 50],
        itemsPerPageText: this.$t('main.ITEMS_PER_PAGE')
      },
      headers: [
        { text: this.$t('main.MYTRIP_TABLE_DEPARTURE'), value: "departure" },
        { text: this.$t('main.MYTRIP_TABLE_DESTINATION'), value: "destination" },
        { text: this.$t('main.MYTRIP_TABLE_TRANSIT'), value: "transit" },
        { text: this.$t('main.CITIZENSHIPS'), value: "citizenships" },
        { text: this.$t('main.TRAVEL_TYPE'), value: "restrictionType" },
        { text: this.$t('main.TRAVEL_DATE_START'), value: "dateStart" },
        { text: this.$t('main.TRAVEL_DATE_END'), value: "dateEnd" },
        { text: this.$t('main.EMAIL_ADDRESS'), value: "email" },
        { text: this.$t('main.EMAIL_UPDATES'), value: "sendUpdates", align: 'center' },
        { text: this.$t('main.GMEVENTS'), value: "showEvents", align: 'center' },
        { text: this.$t('main.INTERNAL_NOTE'), value: "internalNote" },  // TODO
        { text: this.$t('main.CREATED_AT'), value: "createdAt" },  // TODO
        { text: this.$t('main.OPEN_ACTION'), value: "link" },  // TODO
      ],
      items: []
    }
  },
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceSearch = debounce((search) => {
      // Multiple searches might happen, even with debounce
      // We only use the result of this query if the request still matches
      // the current search text in the autocomplete input
      if (search == this.search) {      
        this.getMyJourneyList()
      }
    }, 500)
  },
  mounted() {
    this.$vuetify.lang.current = 'de'
    this.getMyJourneyList()
  },
  methods: {
    getMyJourneyList() {
      let { page, itemsPerPage } = this.options
      const offset = itemsPerPage * (page - 1)

      this.loading = true
      this.$myJourneyRepository.getMyJourneyList(itemsPerPage, offset, this.search)
        .then((result) => {
          this.loading = false
          this.updateItems(result)
        })
        .catch(() => {
          this.loading = false
        })
    },
    updateItems(result) {
      this.totalCount = result.totalCount
      this.items.splice(0) // clear the array and keep it reactive

      for (let item of result.results) {
        let citizenshipList = []
        for (let cit of item.citizenships) {
          citizenshipList.push(cit.citizenshipCountry.nameShort)
        }
        let citizenshipsName = citizenshipList.join(", ")

        let destinationList = []
        for (let dest of item.destinations) {
            destinationList.push(dest.destinationName)
        }
        let destinationName = destinationList.join(", ")


        this.items.push({
          id: item.id,
          departure: item.departureName,
          destination: destinationName,
          transit: item.transitName,
          citizenships: citizenshipsName,
          restrictionLevel: item.restrictionLevel,
          restrictionType: item.restrictionType,
          dateStart: item.dateStart,
          dateEnd: item.dateEnd,
          language: item.language,
          isActive: item.isActive,
          email: item.email,
          sendUpdates: item.sendUpdates,
          showEvents: item.showEvents,
          createdAt: item.createdAt,
          internalNote: item.internalNote,
          link: item.link
        })
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getMyJourneyList()
      },
      deep: true,
    },
    search() {
      this.debounceSearch(this.search)
    },
  },
}
</script>

<style scoped>
.myjourney-main {
  display: flex;
  padding: 2rem;
  justify-content: center;
  /*background-color: #ebeaea;*/
  background-color: #f7f7f7;
  height: 100%;
}

.myjourney-content {
  max-width: 1600px;
  width: 1600px;
}

.myjourney-list {
  padding: 32px;
  background-color: white;
}
</style>
